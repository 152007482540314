/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// @import '../../../../vendor/twbs/bootstrap/dist/scss/bootstrap';

//@import "bootstrap/functions";
//@import "functions";
//@import "variables";
@import "bootstrap/bootstrap.min";
//@import "assets/animate";
//@import "assets/slick";
@import "assets/aos";
@import "fonts";
@import "navbar";
@import "layout";
//@import "media";
