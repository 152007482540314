body {
    font-family: 'Open Sans';
    font-size: 14px;
    line-height: 26px;
    color: #000; 
}

h1 {
    font-size: 3.2rem;
    line-height: 4rem;
    mix-blend-mode: difference;
    color: white;
    font-weight: bold;
    span.h1-span-white {
        mix-blend-mode: difference;
        text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
        color: #000;
        display: block;
        @media (min-width: 992px) {
            mix-blend-mode: unset;   
        }
    }
    span.h1-span-orange {
        text-shadow: -1px 0 #d02c00, 0 1px #d02c00, 1px 0 #d02c00, 0 -1px #d02c00;
        color: #000;
    }
    @media (min-width: 992px) {
        font-size: 6rem;
        line-height: 6.5rem;
        mix-blend-mode: unset;
    }
}

h2 {
    font-size: 1.5rem;
    line-height: 2rem;
    mix-blend-mode: difference;
    color: #000;
    word-wrap: break-word;
    font-weight: bold;
    margin-bottom: 20px;
    @media (min-width: 992px) {
        font-size: 3rem;
        line-height: 3.5rem;
    }
}

h3 {
    color: #d02c00;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 2rem;
    mix-blend-mode: difference;
}

h4 {
    color: #d02c00;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: bold;
}

h5 {
    font-size: 1rem;
    line-height: 1.5rem;
}

section {
    padding: 60px 0 35px 0;
}

a {
    color: #d02c00;
}

span.text-orange-bold {
    font-weight: bold;
    color: #d02c00;
}

#start {
    position: relative;
    background-color: #fff;
    height: 100vh;
    .black-background {
        position: absolute;
        background: #000;
        right: 0;
        top: 0;
        width: 65%;
        height: 100vh;
    }
    .image-background {
        position: absolute;
        background-image: url('../img/teaser-new.JPG');
        background-size: cover;
        background-color: #fff;
        left: 0;
        top: 0;
        width: 35%;
        height: 100vh;
    }
    .info-box {
        border-bottom: 10px solid #d02c00;
    }
}

#verkehrswertermittlung {
    position: relative;
    color: #000;
    h2 {
        color: #000;
    }
    ul {
        list-style-type: none;
        margin: 30px 0 40px 0;
        li {
            padding: 10px 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            &:before {
                content: '';
                background-image: url('../img/list.png');
                position: absolute;
                width: 15px;
                height: 17px;
                left: 15px;
                margin-top: 5px;
            }
        }
    }   
}


#icon {
    position: relative;
    color: #000;
    .icon-box {
        text-align: center;
        img {
            width: 100px;
            margin-bottom: 15px;
        }
    }
}

#architektur {
    position: relative;
    padding: 25px 0 35px 0;
    h2 {
        background-color: #fff;
        color: #000;
        display: inline-flex;
        padding: 5px 10px;
        mix-blend-mode: unset;
    }
    p {
        color: #fff;
    }
}

.architekt-background-black {
    background-image: url('../img/reidner-background-black.jpg');
    background-size: cover;
    background-attachment: fixed;
    padding: 50px 0;
}

#person {
    position: relative;
    ul {
        list-style-type: none;
        margin: 30px 0 20px 0;
        li {
            padding: 10px 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            &:before {
                content: '';
                background-image: url('../img/list.png');
                position: absolute;
                width: 15px;
                height: 17px;
                left: 15px;
                margin-top: 5px;
            }
            span {
                font-weight: bold;
                margin-right: 5px;
            }
        }
    }
    ul.kontakt-list {
        list-style-type: none;
        margin: 30px 0 20px 0;
        padding: 0;
        li {
            padding: 10px 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            &:before {
                display: none;
            }            
            span {
                font-weight: bold;
                margin-right: 5px;
            }
        }
    }    
    @media (min-width: 992px) {
        img.avatar {
            width: 100%;
        }
    }
}

#referenzen {
    position: relative;
    img {
        width: 100%;
        height: 80px;
        object-fit: contain;
        margin-bottom: 20px;
        @media (min-width: 992px) {
            margin-bottom: 70px;
        }
    }
}

#footer {
    position: relative;
    background-color: #000;
    color: #fff;
    padding: 50px 0;
}

.orange-banner {
    background-color: #d02c00;
    padding: 50px 0 40px 0;
}

.orange-background {
    background-color: #d02c00;
    width: 35%;
    top: 0;
    height: 100%;
    position: absolute;
}

ul.social-info {
    color: #fff;
    mix-blend-mode: difference;
    list-style-type: none;
    margin: 30px 0 0 0;
    font-size: 12px;
    position: relative;
    float: right;
    li {
        display: inline-block;
        padding-right: 30px;
        &:after {
            content: 'I';
            position: absolute;
            margin-left: 10px;
            color: #d02c00;
            font-size: 28px;
        }
    }
    a {
        color: #fff;
        &:hover {
            color: #fff;
        }
    }
}

.image-box {
    background-color: #d02c00;
    height: auto;
    position: relative;
    @media (min-width: 992px) {
        height: 350px;
    }
    &:hover .image-box-overlay {
        opacity: .85;
    }
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        display: block;
    }
    .image-box-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: .5s ease;
        background-color: #d02c00;    
    }
    .image-box-text {
        color: white;
        font-size: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;    
    }
    .image-box-mobile {
        background-color: #fff;
        padding: 5px;
        color: #000;
        margin-bottom: 10px;
    }
}

.banner-black-background {
    position: relative;
    background-color: #000;
    color: #fff;
    margin: 70px 0;
    padding: 100px 0;
    text-align: center;
    h2 {
        font-size: 1.8rem;
        line-height: 3rem;
        mix-blend-mode: difference;
        color: white !important;
        font-weight: bold;
        span.h1-span-white {
            mix-blend-mode: difference;
            text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
            color: #000;
            display: block;
        }
        @media (min-width: 992px) {
            font-size: 6rem;
            line-height: 6.5rem;
        }
    }
}

.banner-black-background-image {
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
    background-attachment: fixed;
    top: 0;
    background-image: url('../img/reidner-background-black.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right right;
}

.margin-after {
    margin-bottom: 50px;
} 

.orange-bar {
    background-color: #d02c00;
    width: 100%;
    height: 1px;
}

.logo-startscreen {
    display: none;
    @media (min-width: 992px) {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 7%;
        display: block;
        img {
            height: 87px;
        }
    }
}

.referenzen-box {
    position: relative;
    margin-bottom: 50px;
    @media (min-width: 768px) {
        margin-bottom: 0;
    }
    &:hover .referenzen-box-overlay {
        opacity: .95;
        cursor: pointer;
    }
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        display: block;
    }
    .referenzen-box-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: .5s ease;
        background-color: #d02c00;    
    }
    .referenzen-box-text {
        color: white;
        font-size: 13px;
        line-height: 15px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;    
    }
    .referenzen-box-mobile {
        background-color: #fff;
        padding: 5px;
        color: #000;
        margin-bottom: 10px;
    }
}

#footer {
    ul {
        list-style-type: none;
        li {
            display: inline-block;
            margin-right: 20px;
            a {
                color: #fff;
            }
        }
    }
}